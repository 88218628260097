@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.logo {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
.logo__img {
  .rego-iga & {
    max-width: 168px;
  }
  .rego-local & {
    max-width: 235px;
  }
  .rego-supa & {
    max-width: 168px;
  }
}
.registration__desc {
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 0.9rem;
  color: black;
}
